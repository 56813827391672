<template>
  <v-card >
    <v-card-title>Sign In</v-card-title>
    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="email" :rules="[v => !!v || 'Email is required']" name="email" label="E-mail" prepend-icon="email"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="[v => !!v || 'Password is required']" :type="show ? 'text' : 'password'" name="password" label="Normal with hint text" prepend-icon="lock" hint="At least 8 characters" counter @click:append="show = !show"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-btn class="mr-4" :to="'pass-reset'">
              Password Reset
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :disabled="!valid" color="primary" class="mr-4" @click="submit">
              Sign In
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="errored">
          <v-col cols="8">
            <v-alert type="error" dismissible>
              {{errored}}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
      <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="teal accent-4"
      />
    </v-form>
  </v-card>
</template>

<script>

  export default {
    name: 'signin',
    data: () => ({
      email: '',
      password: '',
      valid: false,
      show: false,
      errored: null
    }),
    computed: {
      loading () {
        return this.$store.getters.loading && this.$store.getters.loading.action === 'signin'
      }
    },
    methods: {
      submit () {
        this.$store.dispatch('signin', {
          email: this.email,
          password: this.password
        }).then(data => {
          const {signedIn, error} = data
          console.log('returned from vuex store', data)
          if (signedIn) {
            this.$store.dispatch('getUsersClasses')
            this.$router.push('/')
          }
          this.errored = error

        })
      }
    }
  }
</script>
